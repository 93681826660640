<template>
  <v-main>
    <v-sheet class="mx-auto mt-4">
      <v-slide-group
        mandatory
        center-active
        v-model="selectedCategory"
        @change="changeCategory"
      >
        <v-slide-item
          v-for="c in categories"
          :key="'slide_category_' + c.id"
          v-slot:default="{ active, toggle }"
          class="mb-3"
        >
          <v-card
            class="mx-2"
            :width="150"
            :color="active ? 'light-blue darken-2' : 'white'"
            @click="toggle"
          >
            <v-img
              v-if="c.image_path"
              class="white--text elevation-4"
              height="75px"
              :src="c.image_path"
            ></v-img>
            <v-card-text
              class="py-0 px-2 text-truncate"
              :class="active ? 'white--text' : ''"
              v-html="$options.filters.translate(c.name, $i18n)"
            ></v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
    <v-container fluid v-if="category">
      <!-- Menus de dia -->
      <h3
        class="headline mb-1"
        v-html="$options.filters.translate(category.name, $i18n)"
      ></h3>
      <div
        class="mb-4 body-2 grey--text text--darken-1"
        v-html="$options.filters.translate(category.description, $i18n)"
      ></div>

      <v-row>
        <easy-serve-product
          v-for="product in products"
          :key="'products' + product.id"
          :product="product"
        ></easy-serve-product>
      </v-row>

      <easy-serve-about-us></easy-serve-about-us>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import EasyServeProduct from './components/EasyServeProduct';
import EasyServeAboutUs from '../components/EasyServeAboutUs';

export default {
  components: {
    EasyServeProduct,
    EasyServeAboutUs,
  },

  data: () => ({
    counter: 0,
    selectedCategory: null,

    finishedCreated: false,
  }),

  computed: {
    ...mapGetters('restaurant', {
      categories: 'categories',
      allProducts: 'products',
      loading: 'loading',
    }),

    category() {
      return this.categories.find(
        category => category.id == this.$route.params.category
      );
    },

    products() {
      return this.allProducts.filter(
        product => product.category_id == this.$route.params.category
      );
    },
  },

  created() {
    this.categories.forEach((category, index) => {
      if (this.$route.params.category == category.id) {
        this.selectedCategory = index;
      }
    });
  },

  methods: {
    showAllergen(allergen) {
      window.events.$emit('snackbar', allergen);
    },

    affected(allergens) {
      return allergens.find(a => this.selected.includes(a.id));
    },

    changeCategory() {
      // Do not change the category if we are loading the restaurant data
      const category = this.categories[this.selectedCategory].id;
      this.$router
        .push({
          name: 'category',
          params: { category: category },
        })
        .catch(() => {});
    },
  },
};
</script>

<style></style>
