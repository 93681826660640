<template>
  <v-col cols="12" sm="6" xl="4">
    <v-card class="mx-auto product" :loading="loading" :loader-height="4">
      <v-img
        v-if="product.image_path"
        :src="product.image_path"
        height="200"
      ></v-img>

      <v-list>
        <v-list-item>
          <v-list-item-content class="subtitle-1 font-weight-medium mr-2">
            <v-list-item-title
              :class="harmful ? 'red--text text--darken-2' : ''"
              v-html="$options.filters.translate(product.name, $i18n)"
            ></v-list-item-title>
          </v-list-item-content>

          <v-list-item-action
            class="subtitle-2"
            :class="harmful ? 'red--text text--darken-2' : ''"
            >{{ product.price | currency_money }}</v-list-item-action
          >
          <v-list-item-action>
            <v-btn color="primary" @click="add(product)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-card-text
        class="pt-0 pb-2 description"
        :class="harmful ? 'red--text text--darken-2' : ''"
        v-html="$options.filters.translate(product.description, $i18n)"
      ></v-card-text>

      <v-card-text class="pt-0 allergens" v-if="product.allergens.length > 0">
        <v-avatar
          size="32"
          class="mr-1 mb-2 elevation-1"
          v-for="allergen in allergens"
          :key="'allergen_' + allergen.id"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <img
                :src="allergen.image_path"
                v-on="on"
                :alt="allergen.name[$i18n.locale]"
                :class="affectedBy(allergen) ? 'affected-by' : ''"
              />
            </template>
            <span>{{ allergen.name | translate($i18n) }}</span>
          </v-tooltip>
        </v-avatar>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    product: Object,
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapGetters('allergens', {
      allAllergens: 'allergens',
      selected: 'selected',
    }),

    allergens() {
      // We need to load the full allergen object using the id's from the products
      return this.allAllergens.filter(allergen => {
        return !!this.product.allergens.find(a => a.id === allergen.id);
      });
    },

    harmful() {
      const found = this.allergens.find(allergen => {
        return this.affectedBy(allergen);
      });

      return found != undefined;
    },
  },

  methods: {
    affectedBy(allergen) {
      return this.selected.includes(allergen.id);
    },

    add(product) {
      this.loading = true;

      this.$store.dispatch('cart/add', product);

      // Set the animation for the product
      setTimeout(() => (this.loading = false), 400);
    },
  },
};
</script>

<style></style>
